<template>
  <div class="container">
    <h1 class="mb-4">> Select Profile :</h1>
    <div class="mb-4">
      <label>It looks like you are attending on more than one occasion.</label>
      <div class="profiles">
        <div
          class="profile-item"
          v-for="(item, key) in userProfiles"
          :key="key"
        >
          <div class="profile-info" @click="switchProfile(item)">
            <div class="profile-name">{{ item.name }}</div>
            <div class="profile-nickname">{{ item.nickName }}</div>
            <div class="profile-date">
              {{ item.date ? item.date : "Sun, Oct 06, 2021 @ 15:45" }}
            </div>
          </div>
          <div class="profile-close-button">
            <img src="/assets/images/icons/close-green.svg" alt="close" />
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <label>Have another bookng?</label>
      <pnw-button
        variant="outline-primary"
        class="w-100"
        size="lg"
        @click="addNewProfile"
      >
        <img src="/assets/images/icons/add.svg" alt="add" />
        CREATE NEW PROFILE
      </pnw-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PnwButton from "../components/PnwButton.vue";
export default {
  components: { PnwButton },
  name: "SelectProfile",
  computed: {
    ...mapGetters({
      user: "currentUser",
      userProfiles: "userProfiles",
    }),
    currentUserEmail() {
      return this.user?.email || "";
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      setProfile: "setProfile",
    }),
    addNewProfile() {
      this.$router.push({ name: this.$routerItems.TICKET_CODE });
    },
    async switchProfile(profile) {
      await this.setProfile(profile.id);
      if (profile.ticketCode) {
        this.$router.push({ name: this.$routerItems.PROFILE });
      } else {
        this.$router.push({
          name: this.$routerItems.TICKET_CODE,
          query: {
            redirect: this.$routerItems.PROFILE,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 600px;
}
.profiles {
  .profile-item {
    display: flex;
    height: 92px;
    margin-bottom: 16px;
    .profile-info {
      cursor: pointer;
      border: 2px solid #6bca76;
      height: 100%;
      padding: 16px;
      .profile-name {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
      }
      .profile-nickname,
      .profile-date {
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
      }
      margin-right: 16px;
      flex-grow: 1;
    }
    .profile-close-button {
      cursor: pointer;
      border: 2px solid #6bca76;
      width: 56px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
